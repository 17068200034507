export function useTokenomicsUrl() {
  const { publicPageDomain } = useRuntimeConfig().public

  function getCoingeckoUrl(coingeckoId: string | null) {
    return `https://coingecko.com/en/coins/${coingeckoId}`
  }

  function getTdUrl(projectId?: string, ticker?: string | null) {
    const encodedTicker = ticker ? encodeURIComponent(ticker) : ''
    return `https://${publicPageDomain}/share/${encodedTicker}/${projectId}/public`.toLowerCase()
  }

  function getProjectUrl(project: { coingeckoId: string | null, ticker: string | null, id: string }) {
    return project.coingeckoId ? getCoingeckoUrl(project.coingeckoId) : getTdUrl(project.id, project.ticker)
  }

  return {
    getCoingeckoUrl,
    getTdUrl,
    getProjectUrl,
  }
}
